import classNames from 'classnames';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { NotificationCtx } from '@/contexts/NotificationContext';
import { useBodyResize } from '@/hooks/useBodyResize';
import { Message } from '@/types/message';

import styles from './roomstay-notification.module.scss';

const Notification = () => {
    const { messages, isShowing, setIsShowing, popMessage } = useContext(NotificationCtx);
    const [currentMessage, setCurrentMessage] = useState<Message | undefined>();
    const notiRef = useRef<HTMLDivElement | null>(null);
    const { marginTop: bodyMarginTop } = useBodyResize();

    useEffect(() => {
        setCurrentMessage(popMessage());
    }, [messages]);

    const measuer = useCallback(() => {
        let height = 0;
        if (!isShowing) {
            height = notiRef.current?.getBoundingClientRect().height || 0;
        }
        document.documentElement.style.setProperty('--noti-height', `${height + bodyMarginTop}px`);
    }, [isShowing]);

    useEffect(() => {
        setIsShowing(!!currentMessage);
    }, [currentMessage]);

    useEffect(() => {
        if (!isShowing) {
            document.documentElement.style.setProperty('--noti-height', '0px');
        }
    }, [isShowing]);

    useEffect(() => {
        if (!notiRef.current) return;
        const target = notiRef.current;
        const observer = new ResizeObserver(measuer);
        observer.observe(target, { box: 'border-box' });
        return () => observer.unobserve(target);
    }, []);

    const _onClose = () => {
        if (currentMessage && currentMessage.id) {
            // localStorage.setItem(currentMessage.id, 'true');
            setCurrentMessage(undefined);
        }
    };

    const closeable = !currentMessage?.preventClosing;

    return (
        <div
            className={classNames(styles.container, {
                [styles.haveContent]: !!currentMessage,
            })}
        >
            <div className={classNames(styles.box, styles.warning, 'container')} ref={notiRef}>
                <div className={styles.background} />
                <div className={styles.main}>
                    {currentMessage ? <span className={styles.messageContent} dangerouslySetInnerHTML={{ __html: currentMessage.content }}></span> : null}
                    {closeable ? (
                        <a className={styles.closeButton} onClick={_onClose}>
                            <div className={styles.closeIcon}></div>
                        </a>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default Notification;
